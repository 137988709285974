
const golfData = [
    // CUSTOMER

    //Workspace: Golf Indoor Customer
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on app type)
        "name": "Indoor - Customer", //(app title)
        "keyUsers": "Indoor facility customers", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "access": "Limited to each customer", //(All staff/Limited)
        "keyUseCases": "<ul><li>Include specific insights for indoor facilities</li></ul>",
        "dataSummary": "<ul><li>History: 13 month rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: '1280e98e-9413-4a99-8f9a-bc54774ce607',
                workspaceId: 'ecbe4f3a-42ce-4ccb-bdba-a1b983acf6ef', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Facility Insights - Indoor",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Range Customer
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on report type)
        "name": "Range - Customer", //(report title)
        "keyUsers": "Range customers",
        "access": "Limited to each site",
        "keyUseCases": "<ul><li>Range usage and insights</li></ul>",
        "dataSummary": "<ul><li>History: From June 2017 (or site specific)</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: '2236cc7c-344c-4d64-a066-f752d87ebe1c',
                workspaceId: 'a47c7c25-8000-4f81-822d-2454d266d1de',
                name: 'Range',
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    //Workspace: Golf Facility Insights
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'customer', //(internal/customer depending on app type)
        "name": "EQM - Customer", //(app title)
        "keyUsers": "EQM customers", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "access": "Limited to each customer", //(All staff/Limited)
        "keyUseCases": "<ul><li>Include specific insights for EQM facilities</li></ul>",
        "dataSummary": "<ul><li>History: 13 month rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'f19607b6-8699-45f8-93d3-9445d4e9f53c', //old reportid'f19607b6-8699-45f8-93d3-9445d4e9f53c',
                applicationId: '4f1c30e9-2424-49cc-854b-ea53c2624549', //'4f1c30e9-2424-49cc-854b-ea53c2624549', (workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Facility Insights - EQM",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // INTERNAL

    // Workspace: Golf Insights
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "TM Unit - Usage Insights", //(app title)
        "keyUsers": "Business Development", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
         //(All staff/Limited)
        "keyUseCases": "<ul><li>Collection of reports showing usage of TM units and associated apps</li></ul>",
        "dataSummary": "<ul><li>History: 25 Months Rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: '13bd393e-0590-4a2c-82f0-88224d4aeb54',
                applicationId: '413df9d2-c21d-4c33-911d-3524fc5e5262', //(workspaceId/applicationId or applicationID depending if you want to use already existing PBI app with it permissions or not)
                name: "Overview",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '84500172-a5d8-4650-9f1a-ef61041d75ab',
                applicationId: '413df9d2-c21d-4c33-911d-3524fc5e5262', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "General Usage",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '6374c8f1-112c-49a7-a8b8-8b02e785420e',
                applicationId: '413df9d2-c21d-4c33-911d-3524fc5e5262', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Trackman Performance Studio",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '760adf94-dffb-40ee-8517-b5a6b8be28dd',
                applicationId: '413df9d2-c21d-4c33-911d-3524fc5e5262', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Tournaments",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'e852861d-0f3f-43bd-88ec-8c5ff758647e',
                applicationId: '413df9d2-c21d-4c33-911d-3524fc5e5262', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "iOS App Usage",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Range Sales
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "TM Range - Usage Insights", //(report title)
        "keyUsers": "Range sales, Customer Success Managers, Business Development",
        "keyUseCases": "<ul><li>Range usage and insights</li><li>Range performance and utilization alerts</li><li>Range tournament insights</li></ul>",
        "dataSummary": "<ul><li>History: From June 2017</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'df2b6bf0-4d1d-47b1-bfb6-4e70e2337107',
                applicationId: '6ce77ca9-5f64-4991-82ce-8113920d7871',
                name: 'TM Range - Usage Insights',
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'ca04f5fb-a760-4310-a4ff-a50555621981',
                applicationId: '6ce77ca9-5f64-4991-82ce-8113920d7871',
                name: 'Range - Tournaments',
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Golf Facility Internal
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "Indoor Golf Facility Overview", //(app title)
        "keyUsers": "Business Development, Sales Managers, Support", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "keyUseCases": "<ul><li>Configuration and usage overview of indoor golf facilities registered in Trackman Portal</li></ul>",
        "dataSummary": "<ul><li>History: 25 month rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'a27051d7-baf7-4267-b30a-77a57d2fcdcf', //old reportid'f19607b6-8699-45f8-93d3-9445d4e9f53c',
                applicationId: 'd5c86dbb-7ee1-4adc-b897-c3af88acfcad', //'4f1c30e9-2424-49cc-854b-ea53c2624549', (workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Indoor Golf Facility Overview",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Golf Support
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "TM Unit Support", //(app title)
        "keyUsers": "Support", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "keyUseCases": "<ul><li>Report with TM unit lookup functionality showing usage and tracking performance</li></ul>",
        "dataSummary": "<ul><li>History: 25 Months Rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: '4323381b-8fa2-4503-bbb1-166680b0de05', 
                applicationId: 'e1d7c261-2ea3-4419-873d-e6f11cffbde8', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TM Unit Support",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Golf Strategy
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "TM Unit Subscription", //(app title)
        "keyUsers": "Management", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "keyUseCases": "<ul><li>Report showing TM Unit subscription overview</li></ul>",
        "dataSummary": "<ul><li>History: 25 Months Rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: '4618907d-9a4a-4111-b14b-7592887e011e',
                applicationId: 'e9df4e84-0375-4e43-9680-732001d4ac14', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TM Unit Subscription",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Consumer App Overview
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "Consumer App Overview", //(app title)
        "keyUsers": "Business Development, R&D", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "keyUseCases": "<ul><li>Report showing consumer app insights</li></ul>",
        "dataSummary": "<ul><li>History: 25 Months Rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'e226e676-4248-4d48-bd95-4cb0f83a7f64', 
                applicationId: '13271caa-c47e-4f6a-99b2-cb4e23bc7543', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Consumer App Overview",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Golf Data Quality
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "TM Unit Data Quality", //(app title)
        "keyUsers": "R&D", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "keyUseCases": "<ul><li>Collection of reports showing tracking data quality related to TM Units</li></ul>",
        "dataSummary": "<ul><li>History: Ranges from 3 Months to 12 Months Rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'c15e043e-11a5-4627-af59-ccfa687aba87',
                workspaceId: 'a2d92458-9ad6-4b60-a640-aacb013aea6c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TM Unit Pickup Rates",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '4ed65c7d-644f-464b-b42b-6e3c499a4e51',
                workspaceId: 'a2d92458-9ad6-4b60-a640-aacb013aea6c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TMIO Pickup Rates",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '2b8855ba-e32b-47c2-b77a-6505e5486a77',
                workspaceId: 'a2d92458-9ad6-4b60-a640-aacb013aea6c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TMIO Diagnostics",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'f9515d68-3121-4b36-bbfc-45b0bf2f4a9e',
                workspaceId: 'a2d92458-9ad6-4b60-a640-aacb013aea6c', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "TM Unit Outliers",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Golf User Insights - RETIRED
//    {
//        "category": "app", //(report/app depending on single or multiple reports to show)
//        "type": 'internal', //(internal/customer depending on app type)
//        "name": "TM Unit User Insights", //(app title)
//        "keyUsers": "Business Development", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
//        "keyUseCases": "<ul><li>Report showing user insights from our TM Units</li></ul>",
//        "dataSummary": "<ul><li>History: 25 Months Rolling</li><li>Updates: Daily</li></ul>",
//        "reports": [
//            {
//                reportId: 'f1e5997c-8e37-4e97-a1a3-862dbf87b6d5',
//                workspaceId: 'f57f21b3-d581-4b88-b8f5-a070dd986b2f', //(workspaceId/appId depending if you want to use already existing PBI app with it permissions or not)
//                name: "TM Unit User Insights",
//                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
//            }
//        ]
//    },

    // Workspace: Range Internal
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "TM Range - Development",
        "keyUsers": "Business Development",
        "keyUseCases": "<ul><li>Range usage and insights</li><li>Range KPI's</li><li>Range tournament insights</li></ul>",
        "dataSummary": "<ul><li>History: From June 2017</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'be5064e3-9cf6-427d-8501-e4b035600f35',
                applicationId: '12b5eeeb-10ee-4c0c-8b03-351e4600d051', //'fbcb72f4-9f7b-4bbc-b4aa-f3501feb70a9'(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Range - Internal",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId:  '1ef49c07-cc14-41a1-b103-288b8e973311',
                applicationId: '12b5eeeb-10ee-4c0c-8b03-351e4600d051', //workspaceId: 'a47c7c25-8000-4f81-822d-2454d266d1de'(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Range - Tournament",
                visible: true //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId:  '2236cc7c-344c-4d64-a066-f752d87ebe1c',
                applicationId: '12b5eeeb-10ee-4c0c-8b03-351e4600d051', //workspaceId: 'a47c7c25-8000-4f81-822d-2454d266d1de'(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Range - Customer",
                visible: true //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    // Workspace: Range DQ
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on report type)
        "name": "TM Range - DQ", //(report title)
        "keyUsers": "R&D, Range operation",
        "keyUseCases": "<ul><li>Range safety boarder settings and incident overview</li><li>Range carry distributions</li></ul>",
        "dataSummary": "<ul><li>History: 30 days rolling</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: 'a952d98f-3c6c-4848-8709-b384efdc2703',
                applicationId: 'e3347693-3ab2-4065-820e-684fda63dec6',
                name: 'TM Range - DQ',
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    },

    //Workspace: Golf PGA
    {
        "category": "app", //(report/app depending on single or multiple reports to show)
        "type": 'internal', //(internal/customer depending on app type)
        "name": "Golf PGA", //(app title)
        "keyUsers": "Business Development", //(Management/Business Development/R&D/Sales/Sales Managers/Support/Operations/Finance/Customers, one of the agreed listed categories)
        "keyUseCases": "<ul><li>Pickup rates and short details from PGA</li></ul>",
        "dataSummary": "<ul><li>History: From 2022</li><li>Updates: Daily</li></ul>",
        "reports": [
            {
                reportId: '1c1f9c6b-c7d3-4005-b870-a8416f07a852',
                workspaceId: 'e2776d27-8595-4fd1-bd7f-99d7ac49d4da', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Teebox Pick Up Rates",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '76565bcc-57ef-4ec9-95fc-9f8006120f71',
                workspaceId: 'e2776d27-8595-4fd1-bd7f-99d7ac49d4da', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Teebox Shots Details",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: 'bf07c704-a467-4b5a-8621-50e20b7f5454',
                workspaceId: 'e2776d27-8595-4fd1-bd7f-99d7ac49d4da', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Approach Pick Up Rates",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            },
            {
                reportId: '9701dd26-3572-4bd2-9c6a-8e55fce48c54',
                workspaceId: 'e2776d27-8595-4fd1-bd7f-99d7ac49d4da', //(workspaceId/applicationId depending if you want to use already existing PBI app with it permissions or not)
                name: "Approach Shots Details",
                visible: false //(true/false depending if you want the filter pane to be visible by default or no)
            }
        ]
    }
]


// ****************** UPDATE ALL THE OBJECTS WITH APP URL AND SPORT ****************** //
function createUrlFromName(name) {
    let url = name.toLowerCase();
    url = url.replace(/[^\w\s-]/g, '-');
    url = url.replace(/[\s-]+/g, '-');
    return url;
}

function updateAppIds(objects) {
    objects.forEach(obj => {
        const urlFromName = createUrlFromName(obj.name);
        const sport = 'golf';
        obj.appId = urlFromName;
        obj.sport = sport;
        obj.reports = obj.reports.map(item => ({...item, appId: urlFromName, sport: sport}));
    });
}

updateAppIds(golfData);
export {golfData};
